import React, { useEffect } from "react";
import styles from "./style.module.css";
// import FooterLogo from "./../../assets/home/footer-logo.svg";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
const Footer = () => {
  const { t } = useTranslation();


  return (
<>
<div className={styles.WrapperBody}>
      <div className={styles.Container}>
        <div className={styles.Wrapper}>
          <Row>
            <Col lg={4} md={12}>
              <h2>Mard Travel </h2>
              <div className={styles.Network}>
              <a target={"_blank"} href="https://t.me/mardtravel"><i class='bx bxl-telegram'></i></a>
              <a target={"_blank"} href="https://instagram.com/mard__travel?igshid=MzRlODBiNWFlZA=="><i class='bx bxl-instagram' ></i></a>
              <a target={"_blank"} href="https://www.facebook.com/search/top?q=mard%20travel"><i class='bx bxl-facebook-square' ></i></a>
              </div>
            </Col>
            <Col lg={2} md={12}>
              <ul>
                <li>
                  <NavLink className={styles.link} to={"/tour-category/4"}>
                    <p className={styles.FooterLink}>{t("Header.0")}</p>
                  </NavLink>
                </li>
                <li>
                <NavLink className={styles.link} to={"/about"}>
                    <p className={styles.FooterLink}>{t("Header.1")}</p>
                  </NavLink>

                </li>
                <li>
                  <NavLink className={styles.link} to={"/terms"}>
                    <p className={styles.FooterLink}>{t("Header.2")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/important"}>
                    <p className={styles.FooterLink}>{t("Header.3")}</p>
                  </NavLink>
                </li>
                
                <li>
                  <NavLink className={styles.link} to={"/aksiya"}>
                    <p className={styles.FooterLink}>{t("Header.4")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/blog"}>
                    <p className={styles.FooterLink}>{t("Header.5")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/contact"}>
                    <p className={styles.FooterLink}>{t("Header.6")}</p>
                  </NavLink>
                </li>
              </ul>
            </Col>

            <Col lg={5} md={12}>
              <ul>

                <Row>
                  <Col lg={6} md={12}>
                  <li>
                    <a href="tel:+99990 320-10-50" className={styles.FooterLinkHead}><i class='bx bx-phone'  ></i>  +99990 320-10-50  Директор Замира Саидовна</a>
                </li>
                <li>
                    <a href="tel:+99893 385-19-19" className={styles.FooterLinkHead}><i class='bx bx-phone'  ></i> +99893 385-19-19 Шохсанам  менеджер</a>
                </li>

                  </Col>
                  <Col lg={6} md={12}>
                  <li>
                    <a href="tel:+99890 351-90-56" className={styles.FooterLinkHead}><i class='bx bx-phone'  ></i>  +99890 351-90-56  Кристина менеджер</a>
                </li>
 

                <li>
                    <a href="tel:+998991357505" className={styles.FooterLinkHead}><i class='bx bx-phone'  ></i> +99899 135-75-05  Нигора менеджер</a>
                </li>
                </Col>
                </Row>
                <li>
                    <a href="mail:tireuzb@mail.ru" className={styles.FooterLinkHead}><i class='bx bx-comment-dots' ></i> tireuzb@mail.ru</a>
                </li>
                <li>
                    <a href="" className={styles.FooterLinkHead}><i class='bx bxs-map'></i>{t("Contact.2")} </a>
                </li>
              </ul>
            </Col>
          </Row>

        </div>
      </div>
      <div className={styles.NetworkMedia}>
              <a target={"_blank"} href="https://t.me/mardtravel"><i class='bx bxl-telegram'></i></a>
              <a target={"_blank"} href="https://instagram.com/mard__travel?igshid=MzRlODBiNWFlZA=="><i class='bx bxl-instagram' ></i></a>
              <a target={"_blank"} href="https://www.facebook.com/MardTravelUzb"><i class='bx bxl-facebook-square' ></i></a>
              </div>
    </div>
    <div className={styles.company_footer}>
    <a href="https://supersiteuz.com/" className={styles.linkCompany}>
{t("Reviews.3")}</a>
    </div>
</>
  );
};

export default Footer;
