import { Suspense } from "react"
import {Routes , Route} from "react-router-dom"
import {RouterData} from "./index-data"
import Header from "./../components/header/index"
import RequestConsultation from "../components/request-consultation/index"
import Footer from "../components/Footer"
import "./style.css"
import logoslide from "./../assets/Headers/logo.svg"
import FixedAnime from "./../components/fixedAnime/index"
function RouterComponent() {
  return (
    <>
    <Header/>
    <FixedAnime/>
    <Routes>
    {RouterData.map(elem => 
        <Route key={elem.id} path={elem.path} element={
            <Suspense fallback={                  
            <div className="spin_wrapp">
            <img src={logoslide} alt="" />
          </div>}>
                {elem.component}
            </Suspense>            
        }/>
        )}
    </Routes>
    <RequestConsultation/>
    <Footer/>
    </>
  )
}

export default RouterComponent