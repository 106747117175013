import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Wrapper } from "../styled-index";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./style.css"
import { CategoryGet } from "../../../../redux/category";
function Partners({ HandleClickClose }) {
  const disptach = useDispatch();
  const {t} = useTranslation();
  useEffect(() => {
    disptach(CategoryGet())
  }, [])
  const dataCategory = useSelector(state => state.category.categoryGet.data)

  const navigate = useNavigate();
  const HandleClickMore = (e) => {
    navigate(`/tour-category/${e.target.id}`);
    window.localStorage.setItem("CategoryId" , e.target.id)
    HandleClickClose();
  };
  function LanguValue() {
    return window.localStorage.getItem("i18nextLng");
  }
  return (
    <>
      <Accordion>
        <AccordionSummary
          style={{background:"white"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{color:"black"}}>{t("Header.0")}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{background:"white"}}>
          <ul style={{ overflowX: "scroll" , margin:"0" }}>
            {dataCategory.map((elem, index) =>
              LanguValue() === "uz" ? (
                  <>
                                  <button id={elem.id} key={index} onClick={HandleClickMore}>
                  {elem.title_uz}
                </button>
            <hr />
                  </>

              ) : LanguValue() === "ru" ? (
                  <>
                                  <button id={elem.id} key={index} onClick={HandleClickMore}>
                  {elem.title_ru}
                </button>
                <hr />
                  </>
              ) : LanguValue() === "en" ? (
                <button id={elem.id} key={index} onClick={HandleClickMore}>
                  {elem.title_en}
                </button>
              ) : null
            )}
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
export default Partners;
